import React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import FeaturedPreschool from "../../components/home/featuredPreschool";
import ProgramHighlights from "../../components/home/programHighlights";
import PreschoolClassesOffered from "../../components/home/preschoolClassesOffered";
import TextJumbotron from "../../components/util/textJumbotron";

export default function ClassesIndexPage({ data }) {
  return (
    <BaseLayout pageTitle="Preschool: How It Works">
      <TextJumbotron heading="How It Works" />
      <FeaturedPreschool />
      <PreschoolClassesOffered />
      <ProgramHighlights />
    </BaseLayout>
  );
}
